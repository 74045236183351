import WebServiceRequest from './WebServiceRequest'


class GetAllPayments extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Payments/GetAll')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}

export {
  GetAllPayments,
}
