<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <!--    <b-modal-->
    <!--        id="modal-create"-->
    <!--        cancelTitle="Cancel"-->
    <!--        centered-->
    <!--        ok-title="create"-->
    <!--        @ok.prevent="createCurrency"-->
    <!--    >-->
    <!--      <validation-observer ref="createCurrencyForm" class="row">-->
    <!--        <validation-provider-->
    <!--            #default="{ errors }"-->
    <!--            class="col-12"-->
    <!--            name="currency-name"-->
    <!--            rules="required"-->
    <!--        >-->
    <!--          <b-form-group-->
    <!--              label="name"-->
    <!--              label-for="currency-name"-->
    <!--          >-->
    <!--            <b-form-input-->
    <!--                id="currency-name"-->
    <!--                v-model="currency.name"-->
    <!--                :state="errors.length > 0 ? false:null"-->
    <!--                name="currency-name"-->
    <!--                placeholder="currency name..."-->
    <!--            />-->
    <!--            <small class="text-danger">{{ errors[0] }}</small>-->
    <!--          </b-form-group>-->
    <!--        </validation-provider>-->
    <!--        <validation-provider-->
    <!--            #default="{ errors }"-->
    <!--            class="col-12"-->
    <!--            name="currency-code"-->
    <!--            rules="required"-->
    <!--        >-->
    <!--          <b-form-group-->
    <!--              label="code"-->
    <!--              label-for="currency-code"-->
    <!--          >-->
    <!--            <b-form-input-->
    <!--                id="currency-code"-->
    <!--                v-model="currency.code"-->
    <!--                :state="errors.length > 0 ? false:null"-->
    <!--                name="currency-code"-->
    <!--                placeholder="currency code..."-->
    <!--            />-->
    <!--            <small class="text-danger">{{ errors[0] }}</small>-->
    <!--          </b-form-group>-->
    <!--        </validation-provider>-->

    <!--        <validation-provider-->
    <!--            #default="{ errors }"-->
    <!--            class="col-12"-->
    <!--            name="currency-exchange-rate"-->
    <!--            rules="required"-->
    <!--        >-->
    <!--          <b-form-group-->
    <!--              label="exchange-rate"-->
    <!--              label-for="currency-exchange-rate"-->
    <!--          >-->
    <!--            <b-form-input-->
    <!--                id="currency-exchange-rate"-->
    <!--                v-model="currency.exchangeRate"-->
    <!--                :state="errors.length > 0 ? false:null"-->
    <!--                name="currency-name"-->
    <!--                placeholder="currency name..."-->
    <!--                type="number"-->
    <!--            />-->
    <!--            <small class="text-danger">{{ errors[0] }}</small>-->
    <!--          </b-form-group>-->
    <!--        </validation-provider>-->
    <!--      </validation-observer>-->
    <!--    </b-modal>-->
    <b-modal
        id="modal-update"
        cancelTitle="Cancel"
        centered
        ok-title="Update"
        @ok.prevent="updateSetting"
    >
      <validation-observer v-if="selectedSetting" ref="updateSettingForm" class="row">
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="currency-name"
            rules="required"
        >
          <b-form-group
              label="public key"
          >
            <b-form-input
                v-model="selectedSetting.publicKey"
                :state="errors.length > 0 ? false:null"
                name="public-key"
                placeholder="key..."
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="currency-code"
            rules="required"
        >
          <b-form-group
              label="secret key"
          >
            <b-form-input
                v-model="selectedSetting.secretKey"
                :state="errors.length > 0 ? false:null"
                name="secret-key"
                placeholder="key..."
            />
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </b-modal>
    <!--    <b-modal-->
    <!--        id="modal-delete"-->
    <!--        cancelTitle="Cancel"-->
    <!--        centered-->
    <!--        ok-title="delete"-->
    <!--        @ok.prevent="deleteCurrency"-->
    <!--    >-->
    <!--      <div class="row">-->
    <!--        <div class="col-md-12">-->
    <!--          <span>Are you sure you want to delete this currency ?</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </b-modal>-->

    <div v-if="payments !== null">

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >

        <h1>Payments</h1>

        <b-row class="my-2">
          <!-- Table Top -->
          <b-col md="3">
            <b-form-group
                class="mb-2 mt-25"
                label="Search"
            >
              <Debouncer @setValue="setSearch"></Debouncer>

            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                class="mb-2 mt-25"
                label="Payment Status"
            >

              <v-select
                  v-model="selectedStatus"
                  :options="[{name:'Payed',value:'Paid'},{name:'Failed',value:'UnPaid'},{name:'All',value:''}]"
                  :reduce="name=> name.value"
                  :clearable="false"
                  dir="ltr"
                  label="name"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="payments"
            bordered
            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            sort-by.sync
            striped
        >

          <template #cell(isPayed)="data">
            <feather-icon v-if="data.item.isPayed" class="text-success" icon="CheckIcon"></feather-icon>
            <feather-icon v-else class="text-danger" icon="XIcon"></feather-icon>
          </template>
          <template #cell(amount)="data">
            <p>{{
                Intl.NumberFormat('en-US')
                    .format(data.item.amount)
              }} $</p>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                class="d-flex align-items-center justify-content-center"
                cols="12"
                sm="12"
            >
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>

import { BCard, BCol, BFormGroup, BFormInput, BModal, BOverlay, BPagination, BRow, BTable } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Helper from '@/libs/Helper'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { GetAllPayments } from '@/libs/Api/Payments'
import Debouncer from '@/views/components/debouncer.vue'

export default {
  title: 'stripe-setting',
  name: 'stripe-setting',
  data() {
    return {
      required,
      payments: null,
      totalCount: null,
      showOverlay: false,
      currentPage: 1,
      currency: {
        name: '',
        exchangeRate: null,
        code: ''
      },
      baseURL: Helper.baseUrl + 'media/gallery/profile/',
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'paymentId',
          label: '#',
          sortable: true
        },
        {
          key: 'userName',
          label: 'user',
          sortable: true
        },
        {
          key: 'orderCode',
          label: 'Order Code',
          sortable: true
        },
        {
          key: 'systemTransactionId',
          label: 'system Transaction Id',
          sortable: true
        },
        {
          key: 'isPayed',
          label: 'Payed',
          sortable: true
        },
        {
          key: 'amount',
          label: 'amount',
          sortable: true
        },
        {
          key: 'sessionId',
          label: 'session Id In Stripe',
          sortable: true
        },

      ],
      pageNumber: 1,
      searchCommand: '',
      selectedSetting: null,
      selectedStatus: '',

    }
  },
  async created() {
    await Promise.all([
      this.getPayments()
    ])
  },
  watch: {
    currentPage: function () {
      this.getPayments()
    },
    selectedStatus: function () {
      this.getPayments()
    }
  },
  methods: {
    setSearch(search) {
      this.searchCommand = search
      this.getPayments()
    },
    setSelectedSetting(item) {
      this.selectedSetting = JSON.parse(JSON.stringify(item))
    },
    async getPayments() {
      let _this = this
      _this.showOverlay = true
      let getAllPayments = new GetAllPayments(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        search: _this.searchCommand,
        status: _this.selectedStatus ? _this.selectedStatus : ''
      }
      getAllPayments.setParams(data)
      await getAllPayments.fetch(function (content) {
        _this.payments = content.data.data
        _this.totalCount = content.data.totalCount
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async updateSetting() {
      let _this = this
      Helper.validateForm(_this, 'updateSettingForm', async () => {
        _this.showOverlay = true
        let updateSetting = new UpdateSetting(_this)
        updateSetting.setParams(_this.selectedSetting.id)
        updateSetting.setPayload(_this.selectedSetting)
        await updateSetting.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `currency successfully updated`,
            },
          })
          _this.getPayments()
          _this.selectedSetting = null
          _this.$bvModal.hide('modal-update')
          _this.showOverlay = false
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })

    },

  },

  components: {
    Debouncer,
    BCard,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>
